* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Segoe UI", sans-serif;
  }
  body {
    height: 100vh;
    display: grid;
    width: 100%;
    place-items: center;
    background-color: black;
    overflow: hidden;
  }
  .logo-container {
    width: 150px;
    height: 250px;
    position: relative;
    display: block;
    animation: zoom 2s linear forwards;
    animation-delay: 2s;
  }
  
  .logo-container::after {
    content: "";
    width: 150%;
    height: 50px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    left: -25%;
    bottom: -17%;
    z-index: 5;
  }
  
  .logo-container span {
    width: 50px;
    background-color: red;
    position: absolute;
  }
  
  .left {
    left: 20px;
    bottom: 0;
    height: 0;
    transform: skewX(-22deg);
    animation: animBars 0.5s linear forwards;
  }

  
  .center {
    top: 0;
    left: 50%;
    transform-origin: center;
    box-shadow: 0 0 50px black;
    z-index: 2;
    transform: rotate(0.75turn);
    animation: animBars 0.5s linear forwards;
    animation-delay: 1s;
    height: 0;
  }
  
  .right {
    right: 20px;
    bottom: 0;
    transform-origin: top right;
    transform: skewX(22deg);
    animation: animBars 0.5s linear forwards;
    animation-delay: 0.5s;
    height: 0;
  }
  @keyframes animBars {
    100% {
      height: 100%;
    }
  }
  @keyframes zoom {
    100% {
      transform: scale(40);
    }
  }