.browse-courses, .browse-current, .browse-more {
    padding: 20px;
    position: relative;
    overflow-y: auto;
    width: 100vw;
}

.browse-courses h2, .browse-current h2, .browse-more h2 {
    margin-bottom: 10px;
    color: #fff;
}

.entire {
    margin-top: 100px;
    height: calc(100vh - 100px);
    overflow: scroll;
    margin-bottom: 100px;

}

.browse-row-courses, .browse-row-current, .browse-row-more {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    scroll-behavior: smooth;
    width: 100%;

}

.placeholder-box-courses {
    position: relative;
    width: 150px;
    height: 225px; /* Adjust as needed */
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto; /* Prevent boxes from shrinking */

    background: linear-gradient(45deg, #db3b83, #a472ef);
    color: black;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.placeholder-box-current {
    position: relative;
    width: 150px;
    height: 225px; /* Adjust as needed */
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto; /* Prevent boxes from shrinking */
    background: linear-gradient(45deg, #887ee5, #f7b8bf);
    color: black;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.placeholder-box-more {
    position: relative;
    width: 150px;
    height: 225px; /* Adjust as needed */
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto; /* Prevent boxes from shrinking */
    background: linear-gradient(45deg, #e691aa, #dbd460);
    color: black;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.placeholder-image-courses, .placeholder-image-more {
    width: 100%;
    height: 50%;
    object-fit: cover;
    transition: opacity 0.3s ease;
    text-align: center;
    font-size: 2em;
}

.overlay {
    position: absolute; /* Position the overlay relative to the placeholder-box */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Center the text */
    align-items: center;
    justify-content: center;
    opacity: 0; /* Hide overlay by default */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}

.placeholder-box-courses:hover .overlay {
    opacity: 1; /* Show overlay on hover */
}

.placeholder-box-current:hover .overlay {
    opacity: 1; /* Show overlay on hover */
}

.placeholder-box-more:hover .overlay {
    opacity: 1; /* Show overlay on hover */
}


.text {
    color: white; /* Text color */
    font-size: 16px; /* Adjust font size as needed */
    text-align: center;
}

.scroll-indicator-courses-end, .scroll-indicator-more-end {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.scroll-indicator-courses-begin, .scroll-indicator-more-begin {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}