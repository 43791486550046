.browse {
    padding: 20px;
    position: relative;
    overflow-y: auto;
    width: 100vw;
}

.browse-language h2, .browse-skills h2, .browse-tech h2 {
    margin-bottom: 10px;
    color: #fff;
}

.entire {
    margin-top: 100px;
    height: calc(100vh - 100px);
    

    overflow: scroll;
    margin-bottom: 100px;

}

.browse-row-language, .browse-row-tech, .browse-row-skill {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    
    scroll-behavior: smooth;

    width: 100%;

}

.placeholder-box {
    position: relative;
    width: 150px;
    height: 225px; /* Adjust as needed */
    background-color: #333;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto; /* Prevent boxes from shrinking */
    
}



.placeholder-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.image-c, .image-java, .image-js, .image-py, .image-r, .image-sh, .image-word, .image-cpp, .image-outlook, .image-excel,
    .image-powerpoint {
    object-position: top left;
}

.image-flask {
    object-position: -170px center;
}

.image-sqlite {
    object-position: -85px center;
}

.scroll-indicator-language-end, .scroll-indicator-tech-end, .scroll-indicator-skill-end {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}


.scroll-indicator-language-begin, .scroll-indicator-tech-begin, .scroll-indicator-skill-begin {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}


.overlay {
    position: absolute; /* Position the overlay relative to the placeholder-box */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Center the text */
    align-items: center;
    justify-content: center;
    opacity: 0; /* Hide overlay by default */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}


.text {
    color: white; /* Text color */
    font-size: 16px; /* Adjust font size as needed */
    text-align: center;
}

.placeholder-box:hover .overlay {
    opacity: 1; /* Show overlay on hover */
    
}

